<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Archive {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Archive this event so that it doesn't show in the admin dashboard.
        </p>
        <p>
          Note: only events that have no joined users can be archived.
        </p>

        <v-alert v-if="event.archived" type="info" tile>
          This event is already archived. Do you want to undo this?
        </v-alert>

        <p v-if="event.locked || !event.auto_locked">
          <v-btn v-if="event.archived" color="primary" @click="unarchive">Un-archive Now</v-btn>
          <v-btn v-if="!event.archived" color="primary" @click="archive">Archive Now</v-btn>
        </p>
      </v-card-text>


    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventLock",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async archive() {
      const response = (await eventManagerService.archiveEvent(this.event.id)).data;
      this.$helpers.toastResponse(this, response, 'Successfully archived event.');
      if (response.status == 'OK') {
        this.$router.push({name: 'eventmanagerArchived', params: {id: this.event.id}});
      }
    },
    async unarchive() {
      const response = (await eventManagerService.unarchiveEvent(this.event.id)).data;
      this.$helpers.toastResponse(this, response, 'Successfully un-archive event.');
      if (response.status == 'OK') {
        this.$router.push({name: 'eventmanagerView', params: {id: this.event.id}});
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Leaderboard Locking', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

